export const siteTitle = 'Window Mart';

export const formBackendUrl = 'https://api.sellmorewindows.com/dfeinmwfemrdrf.php';

export const citiesForForm = [
  'Calgary',
  'Edmonton',
  'Red Deer',
  'Lethbridge',
  'St. Albert',
  'Medicine Hat',
];

export const sentryDsn = '';
